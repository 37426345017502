import React, { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useInterval, useMediaQuery } from 'usehooks-ts';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { Title } from '@components/atoms/Title';
import { SubscriptionOverview, SubscriptionOverviewOffer } from '@src/lib/graphql/generated';
import { adPositions } from '@lib/adition/adPositions';
import { AboSelection } from '../AboSelection/AboSelection';

export interface AboSelectionsProps extends Pick<SubscriptionOverview, 'offers' | 'more' | 'auto_balance'> {
  headlineLeft?: string;
  headlineRight?: string;
}

export const AboSelections = ({ headlineLeft, headlineRight, auto_balance, more, ...props }: AboSelectionsProps) => {
  const offers = useMemo(
    () =>
      auto_balance
        ? props.offers
        : props.offers?.sort((a, b) => {
            if (a.position_right && !b.position_right) {
              return 1;
            }
            if (!a.position_right && b.position_right) {
              return -1;
            }
            return 0;
          }),
    [props.offers, auto_balance],
  );

  const [selectedAbo, setSelectedAbo] = useState<SubscriptionOverviewOffer>(offers.find((abo) => abo.favorite));
  const [isHovered, setIsHovered] = useState(false);
  const screenIsXl = useMediaQuery('(min-width: 1280px)');

  useInterval(
    () => {
      const currentAboIndex = offers.findIndex((abo) => abo.id === selectedAbo?.id);
      const nextAboIndex = (currentAboIndex + 1) % offers.length;
      setSelectedAbo(offers[nextAboIndex]);
    },
    isHovered || !screenIsXl ? null : 3000,
  );

  if (screenIsXl && !selectedAbo) {
    setSelectedAbo(offers[0]);
  }

  function toggleSelectedAbo(abo: SubscriptionOverviewOffer) {
    if (abo.id === selectedAbo?.id && !screenIsXl) {
      setTimeout(() => setSelectedAbo(null), 0);
      return;
    }
    setTimeout(() => setSelectedAbo(abo), 0);
  }

  return (
    <div className="mx-auto mb-32 max-w-screen-md xl:max-w-screen-2xl">
      <AditionInitialization tags={adPositions} />
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={twMerge('flex flex-col gap-4 xl:flex-row')}
      >
        <div className="mt-6 h-fit xl:sticky xl:top-8 xl:mt-0 xl:w-1/4 xl:min-w-80">
          <div className="flex w-full flex-col gap-4">
            {!!headlineLeft && (
              <Title level={3} classProps={{ root: 'text-white text-center mt-6' }}>
                {headlineLeft}
              </Title>
            )}
            {(auto_balance
              ? offers?.slice(0, Math.ceil(offers.length / 2))
              : offers?.filter((e) => !e?.position_right)
            )?.map((abo) => (
              <AboSelection
                key={abo.id}
                abo={abo}
                onClick={() => toggleSelectedAbo(abo)}
                active={abo.id === selectedAbo?.id}
                small
              />
            ))}
          </div>
        </div>
        <AboSelection
          active
          abo={selectedAbo}
          classProps={{
            root: 'row-span-full hidden xl:block flex-grow !p-8',
            tagline: 'hidden',
            title: 'lg:text-4xl',
            favorite: 'xl:mb-4',
          }}
        />
        <div className="mt-6 h-fit xl:sticky xl:top-8 xl:mt-0 xl:w-1/4 xl:min-w-80">
          <div className="flex w-full flex-col gap-4">
            {!!headlineRight && (
              <Title level={3} classProps={{ root: 'text-white text-center mt-6' }}>
                {headlineRight}
              </Title>
            )}
            {(auto_balance
              ? offers?.slice(Math.ceil(offers.length / 2))
              : offers?.filter((e) => e?.position_right)
            )?.map((abo) => (
              <AboSelection
                key={abo.id}
                abo={abo}
                onClick={() => toggleSelectedAbo(abo)}
                active={abo.id === selectedAbo?.id}
                small
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mt-12 text-center prose-a:underline" dangerouslySetInnerHTML={{ __html: more }} />
    </div>
  );
};
