import Head from 'next/head';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { Heading } from '@vgn-medien-holding/vgn-react-components';
import { Button } from '@components/atoms/Button/Button';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { AboSelections } from '@components/molecules/AboSelections/AboSelections';
import { ReviewCard } from '@components/molecules/ReviewCard/ReviewCard';
import { DefaultOnlyLogo } from '@components/templates/layouts/DefaultOnlyLogo';
import type { Maybe, Page, SubscriptionOverview, Meta as MetaType } from '@src/lib/graphql/generated';

export const SubscriptionOverviewPage = ({
  data,
}: {
  data: SubscriptionOverview & Page & { metadata?: Maybe<MetaType> | undefined };
}) => {
  const {
    feedback,
    feedback_headline,
    metadata,
    title,
    subheadline,
    offers,
    auto_balance,
    headline_offers_left,
    headline_offers_right,
    button_text,
    button_link,
    more,
  } = data;

  return (
    <>
      <Head>{Meta(metadata)}</Head>
      <div className="absolute inset-x-0 top-0 h-screen w-full opacity-75">
        <Image copyright="" src="/assets/tvm_assets/bg-waves.png" alt="" fill imageMode="cover" />
        <div className="absolute bottom-0 left-0 h-1/2 w-full bg-gradient-to-t from-black" />
      </div>
      <div className="relative">
        <div className="mx-auto mb-8 max-w-4xl space-y-3 text-white lg:mb-12">
          <Heading level={1} classProps={{ heading: 'heading-1 text-center font-herokid' }}>
            {title}
          </Heading>
          <Heading level={2} classProps={{ heading: 'text-lg text-center max-w-3xl mx-auto' }}>
            <span dangerouslySetInnerHTML={{ __html: subheadline }} />
          </Heading>
        </div>
        {offers && (
          <AboSelections
            offers={offers}
            auto_balance={auto_balance}
            headlineLeft={headline_offers_left ?? ''}
            headlineRight={headline_offers_right ?? ''}
            more={more}
          />
        )}

        {/* {add_epaper && <EPaper data={[]} />} //TODO: Currently no data implementation */}

        <div className="mb-20 mt-16 flex flex-col items-center">
          <Heading level={2} classProps={{ heading: 'text-heading-2 mb-8 font-herokid text-center' }}>
            {feedback_headline}
          </Heading>
          <div className="relative w-full max-w-screen-2xl overflow-auto scrollbar-hide">
            <div className="grid w-full min-w-min grid-flow-col content-center justify-center gap-6">
              {feedback?.map((review, index) => (
                <ReviewCard
                  key={review.name ? review.name + index : index}
                  {...review}
                  classProps={{ root: 'min-w-64 xs:min-w-80 md:min-w-96' }}
                />
              ))}
            </div>
          </div>
          <Button
            title={button_text}
            link={button_link}
            hasAction={false}
            classProps={{ root: 'inline-block pointer-events-auto mt-8 mb-2' }}
            buttonStyle="primary"
          />
        </div>
      </div>
    </>
  );
};

SubscriptionOverviewPage.PageLayout = DefaultOnlyLogo;

export default SubscriptionOverviewPage;
